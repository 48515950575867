import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";

import rick from "../assets/images/artists/rick/profile/rick-artist-profile.jpg";
import rick001 from "../assets/images/artists/rick/work/rick-001.jpg";
import rick002 from "../assets/images/artists/rick/work/rick-002.jpg";
import rick003 from "../assets/images/artists/rick/work/rick-003.jpg";
import rick004 from "../assets/images/artists/rick/work/rick-004.jpg";
import rick005 from "../assets/images/artists/rick/work/rick-005.jpg";
import rick006 from "../assets/images/artists/rick/work/rick-006.jpg";
import rick007 from "../assets/images/artists/rick/work/rick-008.jpg";
import rick008 from "../assets/images/artists/rick/work/rick-009.jpg";
import rick009 from "../assets/images/artists/rick/work/rick-010.jpg";

const Artist_Rick = ({ data }) => (
  <Layout>
    <SEO
      title="Artist Rick Schenk"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Rick Schenk</h1>
        </header>
        <div className="image">
          <img src={rick} alt="rick" className="image left" />
          <p>
            Rick has been tattooing since 2013, and specializes in fineline
            black and grey. Rick is a California native but has lived in a lot
            of other places that are also too expensive. He enjoys uplifting
            stories about animals and depressing stories about people. He will
            most likely sing at you while he’s tattooing you, and for that he is
            very sorry.
          </p>
          <p>
            Contact: bigbrickrick@gmail.com <br />
            Appointments: https://calendly.com/bigbrickrick
            <br />
          </p>
          <hr />
          <h5 style={{ float: `left` }}>
            <a
              href="https://www.instagram.com/rickschenktattoo/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @rickschenktattoo
            </a>
          </h5>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="tiles"
        style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
      >
        <article
          style={{
            backgroundImage: `url(${rick001})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick002})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick003})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick004})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick005})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick006})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick007})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick008})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${rick009})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
      </section>
    </div>
  </Layout>
);
// export const query = graphql`
// query ArtistRick {
//     allInstaNode (limit: 15) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `

export default Artist_Rick;
